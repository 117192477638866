import React, { useEffect } from 'react';
import Typography from '@/components/Typography';
import CheckedIcon from '@/assets/svg/File-checked-icon.inline.svg';
import CrossIcon from '@/assets/svg/Cross-icon.inline.svg';
import UploadIcon from '@/assets/svg/Upload-icon.inline.svg';
import { useStateMachine } from 'little-state-machine';
import Container from '@/features/Hero/Container';
import updateAction from '@/features/Hero/actions';
import toast from 'react-hot-toast';
import { fileSchema } from '@/features/Hero/schema';
import { uniqueId } from 'lodash';

const Step0 = () => {
  const dropzoneRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { actions, state } = useStateMachine({ updateAction });

  function updateFormWithFiles(dataTransfer: DataTransfer) {
    if (state.fileMounted) {
      const oldFiles: File[] = Array.from(state.form.file);
      const newFiles: File[] = Array.from(dataTransfer.files);
      const mergedFiles: File[] = [...oldFiles, ...newFiles];

      actions.updateAction({
        form: {
          ...state.form,
          file: mergedFiles,
        },
      });

      const mergedDataTransfer = new DataTransfer();

      mergedFiles.forEach((file: File) => {
        mergedDataTransfer.items.add(file);
      });
    } else {
      actions.updateAction({
        fileMounted: true,
        form: {
          ...state.form,
          file: Array.from(dataTransfer.files),
        },
      });

      if (inputRef.current) {
        inputRef.current.files = dataTransfer.files;
      }
    }
  }

  function removeFileAtIndex(index: number) {
    const newFileList = Array.from(state.form.file);
    newFileList.splice(index, 1);

    if (newFileList.length > 0) {
      actions.updateAction({
        form: {
          ...state.form,
          file: newFileList,
        },
      });
    } else {
      if (inputRef.current) {
        inputRef.current.files = null;
        inputRef.current.value = '';
      }
      actions.updateAction({
        fileMounted: false,
        form: {
          ...state.form,
          file: null,
        },
      });
    }
  }

  useEffect(() => {
    const element = dropzoneRef.current;
    const input = inputRef.current;
    if (element && input) {
      [
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop',
      ].forEach(event => {
        element.addEventListener(event, e => {
          e.preventDefault();
          e.stopPropagation();
        });
      });

      ['dragenter', 'dragover', 'drop'].forEach(eventName => {
        element.addEventListener(eventName, () => {
          element.classList.add('drop');
        });
      });

      element.addEventListener('drop', e => {
        const dataTransfer = new DataTransfer();

        if (e.dataTransfer) {
          Array.from(e.dataTransfer.files).forEach((file: File) => {
            const isValid = fileSchema.safeParse({ file });
            if (!isValid.success) {
              const { message } = JSON.parse(isValid.error.message)[0];
              toast.error(message, {
                id: 'fileserror',
              });
              return;
            }
            dataTransfer.items.add(file);
          });

          updateFormWithFiles(dataTransfer);
        }
      });
    }
  }, [actions, state.fileMounted, state.form]);

  useEffect(() => {
    if (
      state.fileMounted &&
      (state.form.file?.length === 0 || state.form.file === null)
    ) {
      actions.updateAction({
        fileMounted: false,
      });
    }
  }, [actions, state.fileMounted, state.form.file]);

  return (
    <Container ref={dropzoneRef} className="py-[21px] lg:py-12">
      <UploadIcon width={50} height={38} />
      <label
        htmlFor="upload"
        className="cursor-pointer pt-4 pb-3.5 text-center"
      >
        <Typography
          as="span"
          variant="body-text-1"
          className=" text-center text-yellow"
        >
          Wgraj plik PDF z dysku <br className="lg:hidden" />
          lub przeciągnij go tutaj
        </Typography>
      </label>
      <Typography
        as="p"
        variant="body-text-3"
        className="px-[21px] text-center !font-normal text-light-gray md:px-0"
      >
        Przyjmujemy także JPG i PNG, a&nbsp;maksymalny rozmiar pliku powinien
        wynosić 50 MB.
      </Typography>
      <input
        ref={inputRef}
        id="upload"
        name="upload"
        type="file"
        className="hidden"
        accept="application/pdf, image/png, image/jpeg"
        multiple
        onChange={e => {
          const { files, value } = e.target;
          const dataTransfer = new DataTransfer();

          if (!value && !state.form.file) {
            actions.updateAction({
              fileMounted: false,
              form: {
                ...state.form,
                file: null,
              },
            });
            e.target.value = '';
            e.target.files = null;
            dropzoneRef.current?.classList.remove('drop');
            return;
          }

          if (files) {
            Array.from(files).forEach((file: File) => {
              const isValid = fileSchema.safeParse({ file });
              if (!isValid.success) {
                const { message } = JSON.parse(isValid.error.message)[0];
                toast.error(message, {
                  id: 'invalidfiles',
                });
                return;
              }
              dataTransfer.items.add(file);
            });

            updateFormWithFiles(dataTransfer);
          }
        }}
      />
      <div className="mt-6">
        {state.form?.file &&
          Array.from<File>(state.form.file).map((file: File, index) => (
            <Typography
              variant="body-text-1"
              as="h3"
              className="mb-3 flex flex-col items-center justify-center gap-y-3 text-yellow lg:flex-row lg:gap-x-2.5"
              key={`${uniqueId(file.name)}}`}
            >
              <CheckedIcon />
              {file.name}
              <button
                type="button"
                onClick={() => {
                  if (inputRef.current) {
                    dropzoneRef.current?.classList.remove('drop');
                    removeFileAtIndex(index);
                  }
                }}
              >
                <CrossIcon width={16} height={16} fill="white" />
              </button>
            </Typography>
          ))}
      </div>
    </Container>
  );
};

export default Step0;
