import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import CloseModal from '@/assets/svg/Cross-icon.inline.svg';
import { FormProvider, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import toast from 'react-hot-toast';
import { FormValues, schema } from '@/features/Hero/Modal/schema';
import { checkUrl } from '@/utils/helpers/checkUrl';
import { getReferrerParams } from '@helpfind/gatsby-cookie';
import { ExtendedWindow } from '@/utils/types';
import Step1 from './Step1';
import Step2 from './Step2';

const Modal = ({ toggleModal }: { toggleModal: () => void }) => {
  const [steps, setSteps] = useState(1);
  const methods = useForm<FormValues>({
    mode: 'all',
    resolver: zodResolver(schema),
  });

  React.useEffect(() => {
    (window as ExtendedWindow).dataLayer =
      (window as ExtendedWindow).dataLayer || [];
  }, []);

  const onSubmit = async (data: FormValues) => {
    const formData = {
      ...data,
      agreements: [data.agreements],
      page: window.location.pathname,
      sent_from_url: window.location.href,
      ...getReferrerParams(),
    };

    try {
      const response = await fetch(
        `${checkUrl(process.env.GATSBY_API_URL)}api/sk/contact`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(formData),
        }
      ).then(res => res.json());
      if (response.status === 0) {
        (window as ExtendedWindow)?.dataLayer?.push({
          event: 'form.ajaxSubmit',
          formSubmissionSuccessPay: 'tak',
          product: 'BLACHY',
        });

        setSteps(steps + 1);
      } else {
        toast('Wystąpił błąd podczas przesyłania formularza.');
      }
    } catch (error) {
      toast.error('Wystąpił błąd podczas przesyłania formularza.');
    }
  };

  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="fixed inset-0 z-10 h-full w-full bg-black/30 backdrop-blur-[2px]"
        onClick={toggleModal}
      />
      <div className="fixed left-1/2 top-1/2 z-10 w-[calc(100%-24px)] -translate-x-1/2 -translate-y-1/2 space-y-6 rounded-xl bg-white px-5 pt-7 pb-10 sm:w-fit lg:px-10 lg:pb-12.5 lg:pt-10 ">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="space-y-3.5 "
          >
            {steps === 1 && <Step1 />}
            {steps === 2 && <Step2 />}
          </form>
        </FormProvider>
        <button
          type="button"
          className="absolute top-[calc(100%-48px)] left-1/2 -translate-x-1/2 rounded-full bg-[#FAFBFC] p-4"
          onClick={() => {
            toggleModal();
          }}
        >
          <CloseModal width={16} height={16} />
        </button>
      </div>
    </>,
    document.getElementById('portal') as HTMLElement
  );
};

export default Modal;
