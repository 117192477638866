import React from 'react';
import ReactDOM from 'react-dom';
import CloseModal from '@/assets/svg/Cross-icon.inline.svg';
import Typography from '@/components/Typography';

const ModalWhereToFind = ({ toggleModal }: { toggleModal: () => void }) => {
  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="fixed inset-0 z-10 h-full w-full bg-black/30 backdrop-blur-[2px]"
        onClick={toggleModal}
      />
      <div className="fixed left-1/2 top-1/2 z-10 w-[calc(100%-24px)] -translate-x-1/2 -translate-y-1/2 space-y-6 rounded-xl bg-white px-5 pt-7 pb-10 sm:w-fit lg:px-10 lg:pb-12.5 lg:pt-10">
        <div className="max-w-[368px] space-y-2">
          <Typography as="p" variant="header-3" className="text-blue-dark">
            Gdzie znaleźć kosztorys?
          </Typography>
          <Typography
            as="p"
            variant="body-text-3"
            className="font-normal text-dark-gray"
          >
            Sprawdź korespondencję mailową z ubezpieczycielem, do którego
            zgłoszona została Twoja szkoda. Po oględzinach powinieneś otrzymać
            kosztorys naprawy pojazdu. Najczęściej będzie to plik .pdf lub link
            do konta w systemie, gdzie można pobrać dokument.
          </Typography>
          <Typography
            as="p"
            variant="body-text-3"
            className="font-normal text-dark-gray"
          >
            Jeśli nie możesz znaleźć korespondencji w skrzynce odbiorczej, to
            koniecznie sprawdź zakładkę spam. Może się też zdarzyć tak, że w
            ogóle nie otrzymasz tego dokumentu. W takiej sytuacji koniecznie
            poproś o niego ubezpieczyciela drogą mailową.
          </Typography>
        </div>
        <button
          type="button"
          className="absolute top-[calc(100%-48px)] left-1/2 -translate-x-1/2 rounded-full bg-[#FAFBFC] p-4"
          onClick={() => {
            toggleModal();
          }}
        >
          <CloseModal width={16} height={16} />
        </button>
      </div>
    </>,
    document.getElementById('portal') as HTMLElement
  );
};

export default ModalWhereToFind;
