import React from 'react';
import Typography from '@/components/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import Input from '@/features/Hero/Input';
import isValid from '@/utils/helpers/isValid';
import InfoBox from '@/features/Hero/InfoBox';
import Button from '@/components/Button/Button';
import { StaticImage } from 'gatsby-plugin-image';
import { PrivacyPage } from '@/utils/data/seo';
import { Link } from 'gatsby';

const Step1 = () => {
  const { register, control, formState } = useFormContext();
  return (
    <>
      <div className="flex min-w-min items-center gap-x-3 lg:w-[370px]">
        <StaticImage
          src="../../../assets/images/sprawdz-kosztorys-rzeczoznawca.png"
          className="min-h-[56px] min-w-[56px] lg:min-h-[88px] lg:min-w-[88px]"
          alt="Rzeczoznawca"
        />
        <div className="space-y-0.5">
          <Typography variant="header-4" as="p" className="text-blue-dark">
            Nasz Rzeczoznawca skontaktuje się z Tobą w ciągu 48 h
          </Typography>
          <Typography variant="body-text-4" as="p" className="text-dark-gray">
            Dostaniesz informację, jaką dopłatę do odszkodowania możesz
            otrzymać.
          </Typography>
        </div>
      </div>
      <div className="flex w-full flex-col gap-y-2 lg:w-[370px]">
        <div className="space-y-1">
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({ field }) => (
              <Input
                type="text"
                label="Imię"
                labelColor="dark"
                valid={isValid(formState, 'name')}
                {...field}
                placeholder="Wpisz imię"
              >
                {formState.errors.name?.message && (
                  <InfoBox variant="error">
                    {formState.errors.name?.message as string}
                  </InfoBox>
                )}
              </Input>
            )}
          />
        </div>
        <div className="space-y-1">
          <Controller
            control={control}
            name="phone"
            defaultValue=""
            render={({ field }) => (
              <Input
                type="text"
                label="Numer telefonu"
                labelColor="dark"
                valid={isValid(formState, 'phone')}
                {...field}
                placeholder="Wpisz numer"
              >
                {formState.errors.phone?.message && (
                  <InfoBox variant="error">
                    {formState.errors.phone?.message as string}
                  </InfoBox>
                )}
              </Input>
            )}
          />
        </div>

        <label
          htmlFor="agreementsModal"
          className="mt-1.5 flex cursor-pointer flex-row items-center gap-x-2.5"
        >
          <input
            id="agreementsModal"
            type="checkbox"
            className="cursor-pointer"
            value="Akceptuję politykę prywatności."
            {...register('agreements')}
          />
          <Typography
            as="span"
            variant="body-text-4"
            className="cursor-default text-dark-gray lg:leading-18"
          >
            Oświadczam, że zapoznałem się z{' '}
            <a
              href="/polityka-prywatnosci/"
              className="cursor-pointer text-blue hover:underline"
              title={PrivacyPage.title}
              target="_blank"
              rel="noreferrer"
            >
              polityką prywatności
            </a>{' '}
            i wyrażam zgodę na przekazanie danych do Helpfind Sp. z o.o.
          </Typography>
        </label>
        {formState.errors.agreements?.message && (
          <InfoBox variant="error">
            {formState.errors.agreements?.message as string}
          </InfoBox>
        )}
        <Button
          type="submit"
          variant="contained"
          arrow="next"
          className="mt-1.5 mb-2.5 w-full"
        >
          Proszę o kontakt
        </Button>
      </div>
    </>
  );
};
export default Step1;
