import { z } from 'zod';
import { validatePhoneDebounced } from '@/utils/helpers/validatePhone';

const prev = {
  phone: '',
  isGood: false,
};

export const schema = z.object({
  name: z.string(),
  phone: z.string().refine(
    async phone => {
      if (prev.phone !== phone) {
        prev.phone = phone;
        const isValidPhone = await new Promise(resolve =>
          // eslint-disable-next-line no-promise-executor-return
          validatePhoneDebounced(phone, resolve)
        );
        prev.isGood = isValidPhone as boolean;
        return isValidPhone;
      }
      return prev.isGood;
    },
    { message: 'Wprowadź poprawny numer telefonu.' }
  ),
  agreements: z
    .boolean()
    .refine(agreement => agreement, {
      message: 'Zaakceptuj regulamin.',
    })
    .or(
      z
        .string()
        .refine(agreement => agreement === 'Akceptuję politykę prywatności.', {
          message: 'Zaakceptuj regulamin.',
        })
    ),
});

export type FormValues = z.infer<typeof schema>;
