import React from 'react';
import Container from '@/features/Hero/Container';
import Typography from '@/components/Typography';
import Button from '@/components/Button/Button';
import { useStateMachine } from 'little-state-machine';
import updateAction from '@/features/Hero/actions';

const Step2 = () => {
  const { actions } = useStateMachine({ updateAction });

  return (
    <Container className="drop !items-start px-6.5 py-7.5 px-4 lg:w-[556px] lg:p-15">
      <Typography as="h3" variant="header-3" className="text-left text-yellow">
        Dziękujemy!
      </Typography>
      <Typography
        as="p"
        variant="form-body-text"
        className="pt-2 text-left text-white"
      >
        Wkrótce otrzymasz raport z analizą Twojego kosztorysu na adres e-mail.
        Sprawdź skrzynkę odbiorczą, ewentualnie skrzynkę SPAM.
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          actions.updateAction({
            fileMounted: false,
            step: 0,
            form: {
              file: null,
              password: '',
              email: '',
              phone: '',
              agreements: false,
            },
          });
        }}
        className="mt-3.5 w-full"
      >
        Powrót
      </Button>
    </Container>
  );
};

export default Step2;
