import React, { useEffect } from 'react';
import Typography from '@/components/Typography';
import { useStateMachine } from 'little-state-machine';
import Step0 from '@/features/Hero/Step0';

import updateAction from '@/features/Hero/actions';
import Button from '@/components/Button/Button';
import Modal from '@/features/Hero/Modal';
import ModalWhereToFind from '@/features/Hero/ModalWhereToFind';
import Step1 from '@/features/Hero/Step1';
import Step2 from '@/features/Hero/Step2';
import { toast } from 'react-hot-toast';

const Hero = () => {
  const { actions, state } = useStateMachine({ updateAction });
  const [showModal, setShowModal] = React.useState(false);
  const [showModalWhereToFind, setShowModalWhereToFind] = React.useState(false);

  const toggleModal = () => {
    setShowModal(prev => !prev);
  };

  const toggleModalWhereToFind = () => {
    setShowModalWhereToFind(prev => !prev);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', () => {
        actions.updateAction({
          fileMounted: false,
          step: 0,
          form: {
            ...state.form,
            file: null,
          },
        });
      });
    }
  }, []);

  return (
    <section className="bg-hero-tablet bg-cover bg-top lg:bg-hero-4k">
      <div className="container flex flex-col items-center justify-center py-10 px-4 lg:py-17.5">
        <div>
          <Typography
            as="h1"
            variant="header-1"
            className="text-center text-white"
          >
            Sprawdź za darmo <br /> KOSZTORYS naprawy pojazdu
          </Typography>
          <Typography
            variant="body-text-1"
            as="h2"
            className="pt-3.5 pb-4 text-center text-white lg:pb-7.5"
          >
            Sprawdzimy, czy ubezpieczyciel zaniżył Twoje odszkodowanie –
            prześlij kosztorys <br className="pb-1" />
            <Button
              type="button"
              variant="text"
              className="mt-1 text-yellow sm:min-w-fit"
              onClick={() => {
                toggleModalWhereToFind();
              }}
            >
              Gdzie znaleźć kosztorys?
            </Button>
          </Typography>
        </div>

        {showModal && <Modal toggleModal={toggleModal} />}
        {showModalWhereToFind && (
          <ModalWhereToFind toggleModal={toggleModalWhereToFind} />
        )}

        {state.step === 0 && <Step0 />}

        {state.step === 1 && <Step1 />}
        {state.step === 2 && <Step2 />}

        <div className="flex flex-col items-center justify-center gap-y-2.5 md:flex-row md:gap-x-5 lg:mt-7.5">
          {state.step === 0 && (
            <Button
              type="button"
              variant="contained"
              arrow="next"
              className="mt-5 w-full sm:min-w-[235px]"
              onClick={() => {
                if (state.fileMounted) {
                  actions.updateAction({
                    step: state.step + 1,
                  });
                } else {
                  toast.error('Dodaj plik, aby przejść dalej.', {
                    id: 'addfile',
                  });
                }
              }}
            >
              Sprawdź kosztorys
            </Button>
          )}
          <Button
            type="button"
            variant="text"
            className="link-underline mt-5 w-full sm:min-w-fit"
            onClick={() => {
              toggleModal();
            }}
          >
            Załatw sprawę telefonicznie
          </Button>
        </div>
      </div>
    </section>
  );
};
export default Hero;
