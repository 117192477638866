import React from 'react';
import Typography from '@/components/Typography';
import { StaticImage } from 'gatsby-plugin-image';

const Step2 = () => {
  return (
    <>
      <div className="flex min-w-min items-center gap-x-3 lg:w-[370px]">
        <StaticImage
          src="../../../assets/images/sprawdz-kosztorys-rzeczoznawca.png"
          className="h-[56px] w-[56px] lg:h-[88px] lg:w-[88px]"
          alt="Rzeczoznawca"
        />
        <div className="space-y-0.5">
          <Typography variant="header-4" as="p" className="text-blue-dark">
            Dziękujemy za Twoje zaufanie!
          </Typography>
          <Typography variant="body-text-4" as="p" className="text-dark-gray">
            Nasz rzeczoznawca skontaktuje się <br /> z Tobą w ciągu 48 h od tej
            chwili.
          </Typography>
        </div>
      </div>
      <div className="flex w-full flex-col gap-y-2 lg:w-[370px]">
        <Typography
          as="p"
          variant="body-text-3"
          className="font-normal text-dark-gray"
        >
          Otrzymasz propozycję oferty, która będzie bazować na przeprowadzonej
          wcześniej analizie Twojego kosztorysu. Dzięki powierzeniu nam swojej
          sprawy uzyskasz możliwie najwyższą dopłatę, a także zachowasz spokój
          ducha i cenny czas, bo to my zajmiemy się wszystkimi formalnościami.
        </Typography>
      </div>
    </>
  );
};

export default Step2;
