import React, { ForwardedRef, forwardRef, ReactNode } from 'react';
import clsx from 'clsx';

const Container = forwardRef(
  (
    { children, className }: { children: ReactNode; className?: string },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        className={clsx(
          'border-anim flex w-full flex-col items-center justify-center rounded-lg border-white bg-dark-gray/20 lg:w-[900px] lg:rounded-2xl',
          className
        )}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export default Container;
