import React from 'react';
import Typography from '@/components/Typography';
import clsx from 'clsx';

const InfoBox = ({
  variant,
  children,
}: {
  variant: 'error' | 'info';
  children: React.ReactNode;
}) => {
  return (
    <Typography
      as="p"
      variant="body-text-4"
      className={clsx('rounded-md px-3.5 py-1.5  lg:px-4', {
        'bg-red text-light-gray': variant === 'error',
        'bg-yellow text-dark-gray': variant === 'info',
      })}
    >
      {children}
    </Typography>
  );
};

export default InfoBox;
