import React from 'react';
import Typography from '@/components/Typography';
import clsx from 'clsx';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  classNameExtend?: string;
  children?: React.ReactNode;
  valid?: boolean | null;
  labelColor?: 'dark' | 'light';
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      classNameExtend,
      className,
      name,
      children,
      valid,
      labelColor = 'light',
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <label htmlFor={name} className="flex flex-col gap-y-1">
          <Typography
            as="span"
            variant="body-text-3"
            className={clsx(
              labelColor === 'light' ? 'text-light-gray' : 'text-dark-gray',
              '!font-medium'
            )}
          >
            {label}
          </Typography>
        </label>
        <input
          id={name}
          ref={ref}
          className={clsx(
            className ??
              'min-w-full rounded-md px-3.5 py-3.5 text-dark-gray placeholder:text-dark-orange focus:outline-none focus:ring-0 lg:px-4',
            classNameExtend,
            labelColor === 'light' &&
              'border-none focus:border-none focus:border-transparent ',
            {
              'border-1 border-[#D6D9E3]':
                labelColor === 'dark' &&
                !valid &&
                !(!valid && valid !== null && valid !== undefined),
              'border-1 border-green': valid,
              'border-1 border-red':
                !valid && valid !== null && valid !== undefined,
            }
          )}
          style={{ boxShadow: 'none' }}
          {...rest}
        />
        {children}
      </>
    );
  }
);

export default Input;
