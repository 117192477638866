import { GlobalState } from 'little-state-machine';

export default function updateAction(
  state: GlobalState,
  payload: Partial<GlobalState>
) {
  return {
    ...state,
    ...payload,
  };
}
